import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { notifications } from '@mantine/notifications';
import { alterNavigate, alterThunkLoading } from '../store/schemas/helperFunctionsSlice';

const server = process.env.REACT_APP_DOMAIN
export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUser`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(isLogin())
            thunkApi.dispatch(userInformation(results.message))
            return results
        } catch (error) {

            return error
        }
    }
)


export const signUpWithCode = createAsyncThunk(
    'auth/signUpWithCode',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))

            const response = await fetch(`${server}?auth=false&model=auth&path=/register`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ schoolUid: data.schoolUid, email: data.email, password: data.password, code: data.code, firstName: data.firstName, lastName: data.lastName })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(alterNavigate({ url: "/app/onboarding/login" }))
            thunkApi.dispatch(login({ email: data.email, password: data.password }))
            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (data, thunkApi) => {
        try {
            if (!window.location.pathname.includes('/app/onboarding/verification')) {
                thunkApi.dispatch(alterThunkLoading({ visible: true }))
            }
            const response = await fetch(`${server}?auth=false&model=auth&path=/generateAndSendCode`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, type: "passwordReset", admin: data.admin })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            if (data.admin != undefined) {
                if (!window.location.pathname.includes('/app/onboarding/verification?admin=true')) {
                    thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/verification?admin=true&email=${data.email}&type=reset` }))
                    thunkApi.dispatch(alterThunkLoading({ visible: false }))
                }
            }
            else {
                if (!window.location.pathname.includes('/app/onboarding/verification')) {
                    thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/verification?email=${data.email}&type=reset` }))
                    thunkApi.dispatch(alterThunkLoading({ visible: false }))
                }
            }



            // return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async (data, thunkApi) => {
        try {
            if (!window.location.pathname.includes('/app/onboarding/verification')) {
                thunkApi.dispatch(alterThunkLoading({ visible: true }))
            }
            const response = await fetch(`${server}?auth=false&model=auth&path=/generateAndSendCode`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, type: "verification" })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            if (!window.location.pathname.includes('/app/onboarding/verification')) {
                thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/verification?email=${data.email}&type=reset` }))
                thunkApi.dispatch(alterThunkLoading({ visible: false }))
            }


            // return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const validateCode = createAsyncThunk(
    'auth/validateCode',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))


            if (data.type == 'reset') {
                const response = await fetch(`${server}?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: data.email, code: data.code, type: "verifyCode", admin: data.admin })
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }
                if (data.admin == undefined) {
                    thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/newPassword?email=${data.email}&type=passwordReset&code=${data.code}`, replace: true }))
                }
                else {
                    thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/newPassword?admin=true&email=${data.email}&type=passwordReset&code=${data.code}`, replace: true }))
                }

                thunkApi.dispatch(alterThunkLoading({ visible: false }))

                return results
            }
            else if (data.type == 'passwordReset') {
                const response = await fetch(`${server}?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: data.email, newPassword: data.newPassword, code: data.code, type: "passwordReset", admin: data.admin })
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }
                if (data.admin != undefined) {
                    thunkApi.dispatch(alterNavigate({ url: "/app/onboarding/login?admin=true" }))
                }
                else {
                    thunkApi.dispatch(alterNavigate({ url: "/app/onboarding/login" }))
                }

                //thunkApi.dispatch(login({email:data.email, password:data.newPassword }))

                return results
            }
            else {
                console.log(data)
                const response = await fetch(`${server}?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ email: data.email, code: data.code, type: "verification" })
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }

                await thunkApi.dispatch(getUser())
                thunkApi.dispatch(alterThunkLoading({ visible: false }))
                thunkApi.dispatch(alterNavigate({ url: "/app" }))
                return results

            }



        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)


export const login = createAsyncThunk(
    'auth/login',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}?auth=false&model=auth&path=/login`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, password: data.password, admin: data.admin })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            if (results.message == 'not_verified') {
                thunkApi.dispatch(alterNavigate({ url: `/app/onboarding/verification?email=${data.email}` }))
            }
            else {
                await thunkApi.dispatch(getUser())
                thunkApi.dispatch(alterNavigate({ url: `/app` }))
            }
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)


export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}?auth=false&model=auth&path=/signOut`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(resetAuth())
            localStorage.clear()
            window.location.href = '/app/onboarding'

            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)

export const requestRemovalOfData = createAsyncThunk(
    'auth/requestRemovalOfData',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}?auth=true&model=user&path=/requestRemovalOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            await thunkApi.dispatch(logout())

            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)





export const requestNoSellOfDataPublic = createAsyncThunk(
    'auth/requestNoSellOfDataPublic',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}?auth=false&model=userPublic&path=/requestNoSellOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ first_name: data.first_name, last_name: data.last_name, email: data.email, state: data.state })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            // @Jacoby make naviagation / actions here
            alert('Your request has been submitted. Thank you.')
            thunkApi.dispatch(alterThunkLoading({ visible: false }))
            window.location.reload()
            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)

export const requestNoSellOfDataStudent = createAsyncThunk(
    'auth/requestNoSellOfDataStudent',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}?auth=true&model=user&path=/requestNoSellOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            // @Jacoby make naviagation / actions here
            thunkApi.dispatch(alterThunkLoading({ visible: false }))
            alert('Your request has been submitted. Thank you.')
            window.location.reload()

            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)