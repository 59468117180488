import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider, Text } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { StaticRouter } from "react-router-dom/server";

import './css/App.css';
import './css/vivify.min.css'
import './css/mobile.css'

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';











const Render = ({url}) => {
  if(typeof window == 'undefined'){
    return (
      <MantineProvider withGlobalStyles defaultColorScheme="auto" withNormalizeCSS theme={{
        colors: {
          brand: ['#F0BBDD', '#a6a6a6', '#626262', '#626262', '#454545', '#1f1f1f', '#044EB3', '#044EB3', '#1e1d1e', 'rgb(23, 23, 23)'],
        },
        primaryColor: 'brand',
      }}>
        <StaticRouter location={url}>
          <Notifications />
          <App />
        </StaticRouter>
    </MantineProvider>
    )
  }
  else{
    return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{
      colors: {
        brand: ['#F0BBDD', '#a6a6a6', '#626262', '#626262', '#454545', '#1f1f1f', '#044EB3', '#044EB3', '#1e1d1e', 'rgb(23, 23, 23)'],
      },
      primaryColor: 'brand',
    }}>

      <BrowserRouter>
        <Notifications />
        <App />
      </BrowserRouter>
  </MantineProvider>
    )
  }

 

}


export default Render