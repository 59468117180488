import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentCampaign: {
        schoolName: "Hamilton High School",
        schoolLogo: '/tempAssets/hamiltonhighicon.png',
        themeColor: "#1F3985",
        campaignName: "Hamilton High School Soccer Trip Fundraiser",
        campaignDescription: "Join us in supporting the Hamilton High School Soccer team's enriching trip! Your contributions will help cover travel, accommodation, and more for the team's trip to the 2021 National Soccer Tournament in Orlando, Florida.",
        campaignBanner: '/tempAssets/hamiltonhigh.png',
        campaignURL: "https://schoolsensefundraising.com/campaign/hamilton-high-school-soccer-trip-fundraiser?refferer=jacoby+b.",
        studentView: {
            raisedFunds: 53.35,
            donorViews: 23,
            successfulDonors: 4,
            joinedOnDaysRemaining: 5,
            ranking: 14
        },
        referrerData: {
            name: "Malcolm W.",
            amount: 100.00,
            ranking: 1,
        },
        campaign: {
            totalGoal: 2000.00,
            currentFund: 1000.00,
            daysLeft: 14,
            doners: 13,
            views: 3124,
        },
        donors: [
            {
                id: 1,
                name: 'John Doe',
                total: 100,
                date: '2023-08-13T21:09:06.190Z'

            },
            {
                id: 2,
                name: 'Jane Doe',
                amount: 200,
                date: '2023-08-13T21:09:06.190Z'
            },

        ],
        leaderboard: [
            {
                name: "Avery G.",
                amount: 100.00,
            },
            {
                name: "John C.",
                amount: 50.00,
            },
            {
                name: "Michael S.",
                amount: 100.00,
            },
            {
                name: "Avery G.",
                amount: 100.00,
            },
            {
                name: "John C.",
                amount: 50.00,
            },
            {
                name: "Michael S.",
                amount: 100.00,
            },
            {
                name: "Avery G.",
                amount: 100.00,
            },
            {
                name: "John C.",
                amount: 50.00,
            },
            {
                name: "Michael S.",
                amount: 100.00,
            },
            {
                name: "Avery G.",
                amount: 100.00,
            },
        ],
        prizes: [
            {
                prize: "1 Free Prom Ticket",
                amount: 100.00,
                describtion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },

        ]
    },
    campaignPreviews: []
}

const CampaignSlice = createSlice({
    name: 'Campaigns',
    initialState: initialState,
    reducers: {
        resetCurrentCampaigns: state => initialState,
        resetCurrentCampaignPreviews: (state, action) => {
            state.campaignPreviews = []
        },
        alterCurrentCampaign: (state, action) => {
            state.currentCampaign = action.payload
        },
        alterCurrentCampaignPreviews: (state, action) => {
            state.campaignPreviews = action.payload
        }

    }
})

export const {
    resetCurrentCampaigns,
    alterCurrentCampaign,
    alterCurrentCampaignPreviews,
    resetCurrentCampaignPreviews
} = CampaignSlice.actions

export default CampaignSlice.reducer;
